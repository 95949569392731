import React, { FC } from 'react';
import { ConfigProvider, App } from 'antd';
import HomePage from '@/components/HomePage';

// TODO url 路径添加 /index 前缀
const Home: FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 2,
          borderRadiusLG: 2,
          fontFamily: 'alibaba-puhuiti,Arial,sans-serif'
        }
      }}
    >
      <App>
        <HomePage />
      </App>
    </ConfigProvider>
  );
};

export default Home;
