import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, message, Space, Tabs } from 'antd';
import { LockOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { signIn, useSession } from 'next-auth/react';
import { EncryptData } from '@/libs/RSAUtil';
import styles from './style.module.scss';
import { useRouter } from 'next/router';
import { parseHashUrl } from '@/libs/util';
import type { HomeType } from '@/components/HomePage';
import TimerButton from '@/components/TimerButton';
import { SmsTypeEnum } from '@/libs/constants';
import { SendPhoneSecret } from '@/components/HomePage/Invitation';
import { validatePhone } from '@/components/HomePage/UpdSecret';
import type { SessionType } from '@/types';
import LoginModal from '@/components/HomePage/LoginModal';

export type LoginType = 'ACCOUNT' | 'PHONE';

export type AccountFieldType = {
  username?: string;
  password?: string;
};

export type PhoneFieldType = {
  phone?: string;
  phoneToken?: string;
};

const Login: FC<{
  updHomeType: (v: HomeType) => void;
}> = ({ updHomeType }) => {
  const sessionObj = useSession();
  const session = sessionObj.data as SessionType;
  const router = useRouter();
  const urlResult = parseHashUrl(router.asPath);
  const callbackUrl = (urlResult?.params?.callbackUrl as string) || `${location.origin}/workspace#/dashboard/home`;

  const [accountForm] = Form.useForm();
  const [phoneForm] = Form.useForm();

  const [loginType, setLoginType] = useState<LoginType>('ACCOUNT');
  const [btnLoading, setBtnLoading] = useState(false);

  const handleKeyPress = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      await onLogin();
    }
  };

  useEffect(() => {
    // 在组件挂载时添加事件监听器
    document.addEventListener('keydown', handleKeyPress);

    // 在组件卸载时移除事件监听器
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [loginType]);

  async function onLogin() {
    // CR: 这里的判断有问题
    if (session?.user?.uid && session?.user?.uid.length > 0) {
      if (session?.user?.loginTenantUid) {
        message.info('当前已登陆账号，稍后将直接跳转至工作台');
        setTimeout(() => {
          location.href = '/workspace#/dashboard/home';
        }, 1000);
      } else {
        updHomeType('changeTenant');
      }
      return;
    }

    const { username, password } = accountForm.getFieldsValue();
    const { phone, phoneToken } = phoneForm.getFieldsValue();
    if (loginType === 'ACCOUNT') {
      await accountForm.validateFields();
    } else {
      await phoneForm.validateFields();
    }

    // message.warning(`${username} ${password} ${phone} ${phoneToken}`)
    setBtnLoading(true);
    await signIn('credentials', {
      redirect: false,
      username: EncryptData(username),
      password: EncryptData(password),
      phone: EncryptData(phone),
      phoneToken: EncryptData(phoneToken),
      loginType: loginType
    })
      .then(res => {
        if (res?.ok) {
          message.success('登录成功');
        } else {
          message.error(res?.error || '登录失败');
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  }

  useEffect(() => {
    if (!session?.user?.uid) {
      return;
    }
    if (session?.user?.loginTenantUid) {
      location.href = callbackUrl;
    } else {
      updHomeType('changeTenant');
    }
  }, [session]);

  const sendPhoneSecret = async () => {
    await phoneForm.validateFields(['phone']).then(async value => {
      const { success, error } = await SendPhoneSecret(value.phone, false, SmsTypeEnum.LOGIN);
      if (success) {
        message.success('验证码发送成功！');
      } else {
        message.error(error || '出错了');
      }
    });
  };

  return (
    <LoginModal onCancel={() => updHomeType('home')}>
      <div className={styles['modal-container-right-login-title']}>欢迎使用墨思</div>
      <Tabs style={{ width: '368px', marginTop: '24px' }} centered activeKey={loginType} onChange={activeKey => setLoginType(activeKey as LoginType)}>
        <Tabs.TabPane key={'ACCOUNT'} tab={'账号密码登录'}>
          <Form form={accountForm} size="large">
            <Form.Item<AccountFieldType> name="username" rules={[{ required: true, message: '账户不能为空' }]}>
              <Input placeholder="账户" prefix={<UserOutlined />} autoComplete="username" />
            </Form.Item>

            <Form.Item<AccountFieldType> name="password" rules={[{ required: true, message: '密码不能为空' }]}>
              <Input.Password placeholder="密码" prefix={<LockOutlined />} autoComplete="current-password" />
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane key={'PHONE'} tab={'手机号登录'}>
          <Form form={phoneForm} size="large">
            <Form.Item<PhoneFieldType> name="phone" rules={[{ required: true, message: '手机号不能为空' }, { validator: validatePhone }]}>
              <Input placeholder="11位手机号" maxLength={11} prefix={<MobileOutlined />} autoComplete="tel-local" />
            </Form.Item>

            <Form.Item<PhoneFieldType> name="phoneToken" rules={[{ required: true, message: '验证码不能为空' }]}>
              <Space.Compact style={{ width: '100%' }}>
                <Input.Password type="text" placeholder="输入验证码" prefix={<LockOutlined />} maxLength={6} autoComplete="one-time-code" />
                <TimerButton btnText="获取验证码" onClick={sendPhoneSecret} style={{ marginLeft: 8 }} />
              </Space.Compact>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>

      <div>
        {/*<Checkbox onChange={(v) => {*/}
        {/*  message.warning(v.target.checked)*/}
        {/*}}>自动登陆</Checkbox>*/}
        <a
          style={{ float: 'right' }}
          onClick={() => {
            updHomeType('updSecret');
          }}
        >
          忘记密码
        </a>
      </div>

      <Button
        type="primary"
        size="large"
        className={styles['modal-container-right-submit-btn']}
        onClick={async () => {
          await onLogin();
        }}
        loading={btnLoading}
      >
        登录
      </Button>

      <div className={styles['modal-container-right-protocol']}>
        <span>登录即表示您已阅读并同意</span>
        <a>《用户协议》</a>
      </div>
    </LoginModal>
  );
};

export default Login;
