import React, { FC, useState, useEffect } from 'react';
import Image from 'next/image';
import { Modal, App } from 'antd';
import { useRouter } from 'next/router';
import { Select } from 'antd';
import Login from '@/components/HomePage/Login';
import UpdSecret from '@/components/HomePage/UpdSecret';
import Invitation from '@/components/HomePage/Invitation';
import UserAvatar from '@/components/UserAvatar';
import ChangeTenant, { changeTenant } from '@/components/ChangeTenant';
import { useClientSession, useFetch, useSupportArrayAt } from '@/libs/fe/hooks';
import { SessionType } from '@/types';
import { parseHashUrl } from '@/libs/util';
import logo from '../../../public/logo.png';

import styles from './style.module.scss';
import { GrantedTenantList, GrantedTenantListVO } from '@/pages/api/tenant/types';
import Head from 'next/head';

const { Option } = Select;

export type HomeType = 'home' | 'login' | 'updSecret' | 'invite' | 'changeTenant';

const QuickStart: FC<{ session: SessionType; setHomeType: Function; loading: boolean; grantTenantList: GrantedTenantListVO[] }> = ({
  session,
  setHomeType,
  loading,
  grantTenantList
}) => {
  const { message } = App.useApp();

  return (
    <div
      id="quick-start"
      className={styles['page-container-home-button']}
      onClick={async () => {
        if (!session?.user?.uid) {
          setHomeType('login');
        } else if (!session?.user?.loginTenantUid) {
          if (loading) {
            message.warning('团队信息加载中');
            return;
          }
          if (!grantTenantList?.length) {
            message.warning('您当前未加入任何团队');
            return;
          } else if (grantTenantList.length === 1) {
            await changeTenant(grantTenantList[0].tenantUid, session, `${location.origin}/workspace#/dashboard/home`);
          } else {
            setHomeType('changeTenant');
          }
        } else {
          location.href = '/workspace#/dashboard/home';
        }
      }}
    >
      {session?.user?.uid ? '开始工作' : '立即体验'}
    </div>
  );
};

const getHomeType = (url: string) => {
  const result = parseHashUrl(url);
  let type = result?.route;
  let inviteToken = result?.params?.inviteToken;
  if (type?.startsWith('/')) {
    type = type.substring(1);
  }
  if (type && ['login', 'updSecret', 'invite', 'changeTenant'].includes(type)) {
    return { type, inviteToken };
  } else {
    return { type: 'home' };
  }
};

// TODO url 路径添加 /index 前缀
const Home: FC = () => {
  const [homeType, setHomeType] = useState<HomeType>('home');
  const [modal, contextHolder] = Modal.useModal(); // 当前为定义App的主页，不能用App.useApp()

  const { session } = useClientSession();

  const route = useRouter();
  let { type, inviteToken } = getHomeType(route.asPath);

  useEffect(() => {
    if (homeType !== type) {
      setHomeType(type as HomeType);
    }
  }, [type, route.asPath]);

  // const scrollToEle = (id: string) => {
  //   const ele = document.getElementById(id);
  //   if (!ele) {
  //     return;
  //   }

  //   ele.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  // };

  useEffect(() => {
    document.title = '墨思';
  }, []);

  const { data: grantTenantList, loading } = useFetch<GrantedTenantListVO[]>(async () => {
    if (!session?.user.uid) {
      return [];
    }
    return fetch('/api/tenant/getGrantedTenantList', { method: 'GET' })
      .then(res => res.json())
      .then(({ data }: GrantedTenantList) => data || []);
  }, [session?.user?.uid]);

  useSupportArrayAt(modal);

  const changeToHomeType = (type: HomeType) => {
    if (type == 'home') {
      route.push('/');
    } else {
      route.push('/#/' + type);
    }
  };

  return (
    <div className={styles['page-container']}>
      <div className={styles['page-container-header']}>
        <Image alt="logo" src={logo} width={165} height={40} />
        <div className={styles['page-container-header-user']}>
          {/* <div className={styles['page-container-header-user-use']} onClick={() => scrollToEle('part-1')} >如何使用</div>
          <div className={styles['page-container-header-user-about']} onClick={() => scrollToEle('part-2')} >关于我们</div> */}
          <UserAvatar
            mode="full"
            size={32}
            isPureAvatar={false}
            grantTenantList={grantTenantList}
            whiteName={true}
            onClickAvatar={session?.user?.uid ? undefined : () => changeToHomeType('login')}
            openDefaultClickAvatar={true}
          />
        </div>
      </div>
      <div className={styles['page-container-home']}>
        <img
          alt="backimg"
          src="https://img.alicdn.com/imgextra/i1/O1CN011VZr5H1NatQMKurOJ_!!6000000001587-0-tps-3000-1874.jpg"
          className={styles['page-container-home-backimg']}
        />
        <video id="background-video" autoPlay muted loop className={styles['page-container-home-video']}>
          <source src="https://cloud.video.taobao.com/play/u/null/p/1/e/6/t/1/423007347752.mp4?SBizCode=xiaoer" type="video/mp4" />
        </video>
        <QuickStart session={session} setHomeType={changeToHomeType} loading={loading} grantTenantList={grantTenantList} />
      </div>
      {/* <div id="part-1" className={styles['page-container-use']}>
            <Image alt="image" className={styles['image-1']} src={homeImage} />
            <div className={styles['page-container-use-video']}>
              <video width="1200" height="675" controls>
                <source src="//globalimg.sucai999.com/uploadfile/20211209/267440/132834960598203218.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div id="part-2" className={styles['page-container-about']}>
            <Image alt="image" className={styles['image-1']} src={homeImage} />
            <div className={styles['page-container-about-text']}>关于我们</div>
          </div> */}
      <div className={styles['page-container-copyright']}>
        <a className={styles['page-container-copyright-link']} onClick={() => window.open('https://beian.miit.gov.cn')}>
          浙ICP备2023027080号-1
        </a>
      </div>
      {/* <FloatButton.BackTop visibilityHeight={window.innerHeight} /> */}
      {homeType === 'login' ? <Login updHomeType={changeToHomeType} /> : null}
      {homeType === 'updSecret' ? <UpdSecret updHomeType={changeToHomeType} /> : null}
      {homeType === 'changeTenant' ? <ChangeTenant updHomeType={changeToHomeType} /> : null}
      {homeType === 'invite' && inviteToken ? (
        <>
          <Head>
            <meta property="og:title" content="墨思团队邀请" />
            <meta property="og:url" content={route.asPath} />
            <meta property="og:image" content={logo.src} />
            <meta property="og:description" content="邀请您使用墨思系统~" />
          </Head>
          <Invitation updHomeType={changeToHomeType} token={inviteToken} />
        </>
      ) : null}
      {contextHolder}
    </div>
  );
};

export default Home;
